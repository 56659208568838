.btn {
    border: none;
    box-shadow: none;
    outline: none;
    background: #b227c1;
    color: #ffff;
    border-radius: 7px;
    padding: 18px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &--primary {
        font-size: 20px;
        line-height: 25px;
        letter-spacing: 0.38px;
        text-transform: uppercase;
    }
    &--link {
        background: none;
        border: none;
        box-shadow: none;
        outline: none;
        font-size: 14px;
        line-height: 20px;
        font-weight: 700;
        color: #b227c1;
        display: inline-block;
        cursor: pointer;
    }
    &--danger-outline {
        padding: 12px 16px;
        background: #fff;
        color: #ff5252;
        border: 1px solid #ff5252;
    }
    &--gray-fill {
        padding: 3px 18px;
        color: #fff;
        background: #c4c4c4;
        border-radius: 10px;
        font-weight: 700;
        font-size: 10px;
        line-height: 20px;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 0.153846px;
        text-transform: uppercase;
        color: #ffffff;
        text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.08);
    }
    &--gray-outline{
        @extend .btn--gray-fill;
        background: transparent;
        color: #747D87;
        border: 1px solid #C4C4C4;
        text-transform: capitalize;
    }
}
