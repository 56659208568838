
*,*::before,*::after{
    box-sizing: border-box;
}
::-webkit-scrollbar {
    width: 5px;
    height:5px;
  }
  
  ::-webkit-scrollbar-track {
    background: #f1f1f1; 
     border-radius:4px;
  }
   
  ::-webkit-scrollbar-thumb {
    background: #888; 
    border-radius:4px;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }
html{
    height: 100%;
}
#root{
    height: 100%;
}
body{
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    font-family: 'Lato', sans-serif;
    -webkit-font-smoothing: antialiased;
}
h1,h2,h3,h4,h5,h6,p{
    margin: 0;
}
ul,ol{
    padding: 0;
    margin: 0;
    list-style: none;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
-webkit-appearance: none;
 margin: 0;
}

/* Firefox */
// input[type=number] {
// -moz-appearance: textfield;
// } 