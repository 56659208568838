.login {
  height: 100vh;
  padding: 20px 50px;
  font-family: "Lato", sans-serif;
  overflow-y: visible;

  &__wrapper {
    background: #ffffff;
    height: calc(100% - 10px);
    max-width: 1410px;
    margin: auto;
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
    max-width: 570px;
    margin: auto;
    text-align: center;
    padding-bottom: 20px;
    max-height: calc(100% - 100px);
  }

  .logo {
    width: 120px;
    height: 120px;
    margin: 0 auto;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &__title {
    font-weight: 800;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.2px;
    color: #454545;
    margin-bottom: 20px;
  }

  &__form-section {
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &__button {
    button {
      width: 100%;
    }
  }
}

// Tabs
.tab {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;

  &__nav {
    display: flex;
    align-items: center;
    overflow-x: auto;
    overflow-y: hidden;
    gap: 20px 84px;
    list-style: none;
  }

  &__navItem {
    position: relative;
    white-space: nowrap;
    font-size: 18px;
    line-height: 21px;
    font-weight: 700;
    color: #7a7a7a;
    padding-bottom: 10px;
    cursor: pointer;

    &::before {
      content: "";
      position: absolute;
      display: block;
      width: 0%;
      height: 2px;
      border-radius: 4px;
      top: auto;
      left: auto;
      right: 0;
      bottom: 4px;
      background: #454545;
      transition-property: width;
      transition-duration: 0.3s;
      transition-timing-function: ease-out;
    }

    &:hover,
    &.isActive {
      &::before {
        left: 0;
        right: auto;
        width: 100%;
      }
    }
  }
}

.formgroup {
  margin-bottom: 33px;

  &__label {
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
    letter-spacing: 0.2px;
    color: #454545;
    margin-bottom: 6px;
    text-align-last: left;
  }

  &__mobileWrapper {
    display: flex;
    align-items: center;
    gap: 10px 18px;

    .formgroup__item {
      flex: 1;

      &:first-child {
        max-width: 183px;
      }
    }
  }

  &__item {
    position: relative;
    background: #f1f1f6;
    border-radius: 7px;

    input {
      padding: 16px 20px;
      border: none;
      box-shadow: none;
      outline: none;
      font-size: 18px;
      line-height: 21px;
      font-weight: 400;
      background: transparent;
      color: #454545;
      width: 100%;

      &::placeholder {
        color: #c4c4c4;
        text-transform: capitalize;
      }
    }

    &.password {
      padding-right: 30px;

      input {
        type: password;
      }

      &.visible {
        input {
          type: text;
        }

        .eye-icon::after {
          background-image: url("../../assets/icons/eye-open.svg");
        }
      }

      .eye-icon::after {
        content: "";
        position: absolute;
        top: 50%;
        right: 22px;
        transform: translateY(-50%);
        display: block;
        background-image: url("../../assets/icons/eye-cross.svg");
        background-repeat: no-repeat;
        background-position: center center;
        width: 32px;
        height: 32px;
        background-size: 30px;
      }
    }
  }
}

/* phone-input.css */

.iti {
  width: 100%;
}
.iti__selected-dial-code {
  font-size: 18px;
}
