@tailwind base;
@tailwind components;
@tailwind utilities;

.title {
  margin: 0;
  font-size: 18px;
  letter-spacing: 2px;
  color: var(--header-color);
  display: inline-block;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translatex(-50%);
  padding: 4px 25px;
  border-radius: 0 0 6px 6px;
  border: 2px solid #000;
  border-top: none;
}

.title:before {
  content: "";
  position: absolute;
  right: 100%;
  top: 0;
  width: 25px;
  height: 27px;
  background: #fff;
  transform: skew(15deg) translate(-3px, 0px);
  border-radius: 0 6px 0 0;
  border-right: 2px solid #000;
  border-top: 2px solid #000;
  box-shadow: 12px -2px rgb(168 85 247);
}
.title:after {
  content: "";
  position: absolute;
  left: 100%;
  top: 0;
  width: 25px;
  height: 27px;
  background: #fff;
  transform: skew(-15deg) translate(3px, 0px);
  border-radius: 6px 0 0 0;
  border-left: 2px solid #000;
  border-top: 2px solid #000;
  box-shadow: -12px -2px rgb(168 85 247);
}

@layer components {
  .underline-active::after {
    content: "";
    position: absolute;
    bottom: -2px; /* Adjust if needed */
    left: 0;
    width: 100%;
    height: 2px;
    background-color: black;
    transition: all 0.6s ease-out;
  }

  .underline-inactive::after {
    content: "";
    position: absolute;
    bottom: -2px; /* Adjust if needed */
    left: 0;
    width: 0;
    height: 2px;
    background-color: transparent;
    transition: all 0.6s ease-out;
  }
}
