.table-container {
  overflow-y: auto;
  overflow-x: auto;
}

thead th {
  position: sticky;
  top: 0;
  z-index: 10;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.table-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: thin; /* Firefox */
  scrollbar-color: rgb(233, 224, 224) #f1f1f1; /* Firefox */
}

/* WebKit-based browsers (Chrome, Safari) */
.table-container::-webkit-scrollbar {
  width: 4px; /* Adjust the width as needed */
  height: 4px;
  display: none;
}

.table-container::-webkit-scrollbar-thumb {
  background-color: rgb(236, 216, 216); /* Adjust the color as needed */
  border-radius: 10px; /* Adjust the border-radius for rounded corners */
}

.table-container::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Adjust the track color as needed */
}

.shimmer-row {
  position: relative;
  overflow: hidden;
}

.shimmer-content {
  background: linear-gradient(to right, #f6f7f8 0%, #edeef1 50%, #f6f7f8 100%);
  background-size: 200% auto;
  animation: shimmer 3s infinite linear;
  width: 100%;
  height: 100%;
}
@keyframes shimmer {
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
}
/* Responsive styles */
@media (min-width: 768px) {
  .shimmer-content {
    height: 100px; /* Adjust height as needed */
  }
}
