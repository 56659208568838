.shimmer {
  animation: shimmer 3.5s infinite linear;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.8) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  background-size: 200% 100%;
}

@keyframes shimmer {
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
}

// @keyframes rise-up {
//   0% {
//     transform: translateY(100%);
//   }
//   30% {
//     transform: translateY(-30%);
//   }
//   60% {
//     transform: translateY(-15%);
//   }
//   100% {
//     transform: translateY(0%);
//   }
// }

// .rise-up {
//   animation: rise-up 3s ease-in-out forwards;
// }
