@keyframes borderBottomAnimation {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

.animated-border {
  position: relative;
  overflow: hidden;
}

.animated-border::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  width: 100%;
  background-color: #b22c71;
  animation: borderBottomAnimation 2s linear infinite;
}
